var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('PixIntegradorPagamentoDialog',{ref:"PixIntegradorPagamentoDialog",attrs:{"propTipoCobranca":1}}),_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Unidades")]),_c('v-spacer'),(_vm.allowCreate)?_c('v-btn',{staticClass:"ma-1",attrs:{"to":"/main/unidade/create","color":"primary"}},[_vm._v("Criar")]):_vm._e()],1),_c('v-card-text',[_c('v-checkbox',{attrs:{"label":"Exibir somente unidades ativas","hide-details":"auto"},model:{value:(_vm.exibirSomenteAtivas),callback:function ($$v) {_vm.exibirSomenteAtivas=$$v},expression:"exibirSomenteAtivas"}}),[_c('v-text-field',{attrs:{"label":"Buscar","single-line":"","append-icon":"mdi-magnify","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-data-table',{attrs:{"multi-sort":"","headers":_vm.headers,"items":_vm.itemsFiltered,"search":_vm.search,"loading-text":"Carregando...","footer-props":{
              itemsPerPageOptions: [-1]
            },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.nome_fantasia",fn:function(ref){
            var item = ref.item;
return [_c('open-new-tab-component',{attrs:{"propObjectLabel":("" + (item.nome_fantasia)),"propObjectId":item.id,"propToolName":"Unidade"}})]}},{key:"item.status_id",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.statusMap[item.status_id] || 'Favor Preencher')+" ")]}},{key:"item.is_franchise",fn:function(ref){
            var item = ref.item;
return [(item.is_franchise)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.cnpj",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCpfCnpj")(item.cnpj))+" ")]}},{key:"item.contrato_data_assinatura",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.contrato_data_assinatura))+" ")]}},{key:"item.financeiro_data_primeira_parcela",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.financeiro_data_primeira_parcela))+" ")]}},{key:"item.valor_parcela",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(item.valor_parcela))+" ")]}},{key:"item.log_ultimo_data_hora",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.log_ultimo_data_hora))+" ")]}},{key:"item.is_active",fn:function(ref){
            var item = ref.item;
return [(item.is_active)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")]):_vm._e()]}}],null,true)})]],2)],1),_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Parcelas em atraso")]),_c('v-spacer')],1),_c('v-card-text',[(_vm.financeiroParcelaAtraso.length > 0)?_c('v-text-field',{attrs:{"label":"Buscar","single-line":"","append-icon":"mdi-magnify","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),(_vm.loadingEmAtraso)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):(_vm.financeiroParcelaAtraso.length > 0)?_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.financeiroHeaders,"items":_vm.financeiroParcelaAtraso,"footer-props":{
          itemsPerPageOptions: [-1]
        },"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.unidade_nome_fantasia",fn:function(ref){
        var item = ref.item;
return [_c('open-new-tab-component',{attrs:{"propObjectLabel":("" + (item.unidade_nome_fantasia)),"propObjectId":item.unidade_id,"propToolName":"Unidade"}})]}},{key:"item.valor_integral",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(item.valor_integral))+" ")]}},{key:"item.vencimento",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.vencimento))+" ")]}},{key:"item.qtd_dias_atraso",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDaysDiff(item.vencimento))+" ")]}},{key:"item.log_ultimo_data_hora",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.log_ultimo_data_hora))+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":""},on:{"click":function($event){return _vm.showPix(item)}}},[_vm._v(" PIX ")])]}},{key:"body.append",fn:function(ref){
        var headers = ref.headers;
return [_c('tr',[_c('td',[_c('b',[_vm._v("Totais")])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm._f("formatPriceValue")(_vm.sumField(_vm.financeiroParcelaAtraso, 'valor_integral'))))])])])]}}],null,true)}):_vm._e()],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.carregarFinanceiroParcelaAtraso()}}},[_vm._v("carregar")])],1)],1),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.dialogFeriados),callback:function ($$v) {_vm.dialogFeriados=$$v},expression:"dialogFeriados"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Baixar o json dos feriados do ano ")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Ano","single-line":"","hide-details":"","type":"number"},model:{value:(_vm.anoFeriado),callback:function ($$v) {_vm.anoFeriado=$$v},expression:"anoFeriado"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","disabled":_vm.anoFeriado <= 0 || _vm.anoFeriado < new Date().getFullYear(),"loading":_vm.loadingGerarFeriados},on:{"click":_vm.handleGerarAquivoFeriadoAno}},[_vm._v("baixar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }